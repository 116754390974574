@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

html, body {
	background-color: #fff;
	color: #636b6f;
	font-family: 'Nunito', sans-serif;
	font-weight: 200;
	min-height: 100vh;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-background-size: cover;
	-moz-background-size: 300px;
	-o-background-size: 300px;
	background-size: 300px;
	background-attachment: fixed;
	background-repeat: no-repeat;
	z-index: -1;
}
.card-panel {
	background-color: rgba(255, 255, 255, 1) !important;
	color: black !important;
}
.modal-content {
	color: #636b6f;
}
p {
	color: #636b6f;
}
