#chat {
  width: 100%;
  height: 400px;
  overflow: auto;
}

#chat::-webkit-scrollbar {
  width: 8px;
  background-color: #F0F0F0;
}

#chat::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 4px;
}

#chat::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}

#chat::-webkit-scrollbar-track {
  background-color: #F0F0F0;
}
