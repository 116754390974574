 header, main, footer {
  padding-left: 260px;
  padding-right: 10px;
}
/*dashboard styles*/
.collapsible .active .collapsible-header i:nth-of-type(2) {
  transform: rotate(90deg);
}

#menu ul li .menu-title{
  font-weight:800!important;
}
#menu .sidenav .menu-title{
  border-top:solid #CDCDCD 1px!important;
  font-weight:800!important;
  color:#000000;
  font-size:12px!important;
  text-transform: uppercase!important;
  letter-spacing:1px;
  padding:0 16px;
}
#menu .sidenav .menu-title i{
  font-size:30px!important;
}
#menu .sidenav li > a > i, 
#menu .sidenav li > a > [class^="mdi-"], 
#menu .sidenav li > a li > a > [class*="mdi-"], 
#menu .sidenav li > a > i.material-icons{
  margin:0 1rem 0 0!important;
  padding:0!important;
}

@media only screen and (max-width : 992px) {
  header, main, footer {
    padding-left: 10px;
  }
}
