.App {
  text-align: center;
}
.title-1{
	font-size:24px;
	margin:15px 0px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 .modal.full {
      width: 100vw;
      max-height: 100vh;
      height: 100vh !important;
      top:0 !important;
  }
.scroll-box {
  overflow-y: scroll;
  height: 30vh;
  padding: 1rem
}
/*.datepicker-modal,.timepicker-modal{
	width: 60% !important;
	height: 60% !important;
}*/
.datepicker-date-display {
    background-color: #9764bd !important;
}
.timepicker-digital-display{
    background-color: #9764bd !important;
}
.ratio {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
  }

  .img-circle {
    border-radius: 50%;
  }

  .img-responsive {
    display: block;
    height: auto;
    max-width: 100%;
  }
.table-wrapper {
  overflow-x: scroll;
}
table tr td {
    white-space: nowrap;
}
tr td {
  max-width: 300px !important;
  white-space:normal;
}
.slide-left {
        -webkit-animation: slide-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
                animation: slide-left 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
}
@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px) rotate(20);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0) rotate(20);
  }
  100% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
}
.padding-10{
  padding: 10px;
}
.padding-20{
  padding:20px;
}
.carton {
  position: relative;
  width: 390px;
  height: 250px;
  margin-top: 6rem;
  transform-style: preserve-3d;
	perspective: 800px;
}
.side {
  position: absolute;
  top:6px;
  right: -97px;
  width: 110px;
  height: 240px;
  background-color: #B77F3C;
  z-index: 2;
  transform: rotateY(28deg);
  transform-style: preserve-3d;
}
.front {
  width: inherit;
  height: inherit;
  background-color: #E2AD6D;
  transform-style: preserve-3d;
	padding:10px;
}
.bold{
  font-weight: bold;
}

.barcode {
	grid-column-start: span 1;
	display: grid;
	margin: 10px 0 0;
	grid-template-rows: 1fr min-content;
	&__scan {
		background: linear-gradient(to right, #333 2%, #333 4%, transparent 4%, transparent 5%, #333 5%, #333 6%, transparent 6%, #333 6%, #333 8%, transparent 8%, transparent 9%, #333 9%, #333 10.5%, transparent 10.5%, transparent 11%, #333 11%, #333 12%, transparent 12%, transparent 13.5%, #333 13.5%, #333 15%, #333 17%, transparent 17%, transparent 19%, #333 19%, #333 20%, transparent 20%, transparent 21%, #333 21%, #333 22%, transparent 22%, transparent 23.5%, #333 23.5%, #333 25%, transparent 25%, transparent 26.5%, #333 26.5%, #333 27.5%, transparent 27.5%, transparent 28.5%, #333 28.5%, #333 30%, transparent 30%, transparent 32%, #333 32%, #333 34%, #333 36%, transparent 36%, transparent 37.5%, #333 37.5%, #333 40%, transparent 40%, transparent 41.5%, #333 41.5%, #333 43%, transparent 43%, transparent 46%, #333 46%, #333 48%, transparent 48%, transparent 49%, #333 49%, transparent 49%, transparent 50%, #333 50%, #333 51%, transparent 51%, transparent 53%, #333 53%, #333 54.5%, transparent 54.5%, transparent 56%, #333 56%, #333 58%, transparent 58%, transparent 59%, #333 59%, #333 60%, #333 62.5%, transparent 62.5%, transparent 64%, #333 64%, #333 64%, #333 67%, transparent 67%, transparent 69%, #333 69%, #333 70%, transparent 70%, transparent 71%, #333 71%, #333 72%, transparent 72%, transparent 73.5%, #333 73.5%, #333 76%, transparent 76%, transparent 79%, #333 79%, #333 80%, transparent 80%, transparent 82%, #333 82%, #333 82.5%, transparent 82.5%, transparent 84%, #333 84%, #333 87%, transparent 87%, transparent 89%, #333 89%, #333 91%, transparent 91%, transparent 92%, #333 92%, #333 95%, transparent 95%);
		height:25px;
		width:130px;
	}
	&__id {
		letter-spacing: 4px;
		padding: 2px 0 0;
		color: black;
		font: 700 16px/1 'Jura';
	}
}
.cente {
	display: flex;
	justify-content: center;
	align-items: center;
}
.container-medium{
	margin: 0 auto;
  padding:100px;
	width: 85%;
}
@media (max-width:1450px){
  .container-medium{padding:50px;width:100%;}
}
@media (max-width:1000px){
  .container-medium{padding:0px;width:100%;}
}
.custom-file-container__image-multi-preview{
	width:30%;
	height:250px; 
	border-radius:20px;
	margin:10px;
	background-size:cover;
	background-position:center;
	background-repeat: no-repeat;
	display:inline-block;
	box-shadow:0 8px 16px 0 rgba(0,0,0,0.2);
}
.custom-file-container__image-multi-preview__single-image-clear{
	cursor:pointer;
	position:relative;
	right:10px;
}
.custom-file-container__image-multi-preview__single-image-clear__icon{
	color:black;
	font-size:30px;
}
.custom-file-container__image-preview{
	background-image: none !important;
}
select[required]{
	display: block;
	height: 0px;
	padding: 0px;
	width: 0px;
	position: absolute;
}
#toast-container {
	top: auto !important;
	right: auto !important;
	bottom: 10%;
	left:7%;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 50px;
    width: 20px;border:solid pink;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.material-placeholder{
	display:inline-block;
}
.materialboxed{
	width:200px;
	margin:20px;
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.5);
}
strong{
	font-weight:bold;
}
.m-p-0{
    margin:0!important;;
    padding: 0!important;
}
.margin-left-10{
  margin-left: 10px;
}

.pointer{
  cursor:pointer;
}

#table-catalogo tr th{
  text-align: center;
}
#table-catalogo tr td{
  margin:0!important;;
  padding: 0 2px!important;
  font-size: 10px!important;
  font-weight:bold;
  line-height:1.1;
  white-space: pre-line;
}

#table-departamentos tr td {
    margin: 0 !important;
    padding: 0 2px!important;
    font-size: 12px !important;
    font-weight: bold;
    line-height: 1.1;
    white-space: pre-line;
}
#table-catalogo tr td .image-gallery{ display: flex; justify-content: center; align-items: center;}
#table-catalogo tr td .image-gallery .image-gallery-content,
#table-catalogo tr td .image-gallery .image-gallery-content div,
#table-catalogo tr td .image-gallery .image-gallery-content div div,
#table-catalogo tr td .image-gallery .image-gallery-content div div div,
#table-catalogo tr td .image-gallery .image-gallery-content div div div div{height:76px!important; margin:0; padding:0;}
#table-catalogo tr td .image-gallery .image-gallery-content div div div div img{max-height:100%!important;max-width:100%!important;}

.input-field > label{
  font-size:11px!important; font-weight:600;letter-spacing:1px;
  top:-10px!important; left:16px!important; 
}

.input-field > label:not(.label-icon).active{
  left:-1px!important; 
  top:2px!important;
}
containerModal #modal1,
#modal1{
  border-radius:12px;
  width:90vw!important;
  max-width:90vw!important;
}
span.badge{height:auto!important;}

.btn-flotante {
	font-size: 16px;
	text-transform: uppercase; 
	font-weight: bold;
	position: fixed!important;
	bottom: 40px;
	right: 40px;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
select, .los-input{background-color:#ffffff!important;border: 1px solid rgb(235, 235, 235)!important;padding:0 8px!important}
.hero {min-height:100vh; width:100%;
  background: rgb(194,194,194);
  background: linear-gradient(40deg, rgba(194,194,194,1) 0%, rgba(128,128,128,1) 51%, rgba(194,194,194,1) 100%);;
}
.container-ab{
  margin: 0 auto;
  display:flex;
}
#container-a, #container-a{float: left;}
.container-a{
  margin:0; padding:40px 6px;
}
.container-a .circulo{
  background-color: #CDCDCD;
  border-radius:100%;
  color: #ffffffff;
  font-weight: bold;
  font-size:35px;
  width: 40px!important;
  height:40px!important;
  margin:0;padding:0;
}
.container-a .circulo img{
  width:90px;
}
.container-a .linea{
  background-color: #CDCDCD;
  width: 4px!important;
  height:40px!important;
  margin:0;padding:0;
}
.cero{
  margin:0!important; padding:0!important;
}
.p-2{
  margin:12px!important; padding:2px!important;
}
.linea{
  background-color: #CDCDCD;
  width: 4px!important;
  height:40px!important;
  margin:0;padding:0;
}
.centrar-l{
  display: flex;
  align-items: center;
  justify-content: left;
}
.active-bg{background-color:#999999!important;}
.active-font{color:#999999!important;}
.container-a .pasos{height:45px;margin:0;padding:0;}
.container-a .pasos p{color:#CDCDCD;
  font-size:12px; text-transform:uppercase; font-weight:900; margin:0; padding:0; 
  text-align:left; width:100%;}
.centrar{display: flex;
  align-items: center;
  justify-content: center
}
@media (max-width: 1440px) {
  .container-a .circulo img{
    width:60px;
  }
}
