.tab.disabled a{
	color:grey !important;
}
.tab a.active{
	color:black !important;
	font-weight: bold;
}
.tabs .tab a{
	color:black;
}
